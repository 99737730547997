// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-photos-js": () => import("/opt/build/repo/src/pages/photos.js" /* webpackChunkName: "component---src-pages-photos-js" */),
  "component---src-pages-places-2-stay-js": () => import("/opt/build/repo/src/pages/places-2-stay.js" /* webpackChunkName: "component---src-pages-places-2-stay-js" */),
  "component---src-pages-posts-js": () => import("/opt/build/repo/src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-registry-js": () => import("/opt/build/repo/src/pages/registry.js" /* webpackChunkName: "component---src-pages-registry-js" */),
  "component---src-pages-rsvp-js": () => import("/opt/build/repo/src/pages/rsvp.js" /* webpackChunkName: "component---src-pages-rsvp-js" */),
  "component---src-pages-thainq-js": () => import("/opt/build/repo/src/pages/thainq.js" /* webpackChunkName: "component---src-pages-thainq-js" */),
  "component---src-pages-things-2-do-js": () => import("/opt/build/repo/src/pages/things-2-do.js" /* webpackChunkName: "component---src-pages-things-2-do-js" */),
  "component---src-pages-where-js": () => import("/opt/build/repo/src/pages/where.js" /* webpackChunkName: "component---src-pages-where-js" */)
}

